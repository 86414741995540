import Vue from "vue";
import VueRouter from "vue-router";

import About from "../views/About.vue";
import Login from "../views/Login.vue";
import Blank from "../views/Blank.vue";
import DBViews from "../views/DBViews.vue";
import {
  getDecodedTokenFromAuthHeader,
  hasToLogin,
} from "../managrx/AutAndAuthServices";

Vue.use(VueRouter);
const ADMIN_ROLE = "admin";

const routes = [
  {
    path: "/adminPanel/users",
    name: "UsersPanel",
    meta: {
      requiredAuthorization: true,
      roles: ADMIN_ROLE,
    },
    component: () =>
      import(/* webpackChunkName: "admin" */ "../views/AdminPanel.vue"),
  },
  {
    path: "/profile",
    name: "UserProfile",
    component: () => import("../views/UserProfile.vue"),
  },
  {
    path: "/db-views",
    name: "DBViews",
    component: DBViews,
  },
  {
    path: "/blank",
    name: "Blank",
    component: Blank,
  },
  {
    path: "/sortable-assigned-cost-items",
    name: "SortableAssignedCostItems",
    component: () => import("../views/SortableAssignedCostItems.vue"),
  },
  {
    path: "/CFManager",
    name: "CFManager",
    component: () => import("../views/CFViewer.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },

  {
    path: "/about",
    name: "About",
    component: About,
  },

  {
    path: "/establishment",
    name: "Establishment",
    component: () =>
      import(
        /* webpackChunkName: "establishment" */ "../views/Establishment.vue"
      ),
  },
  {
    path: "/establishment/:id",
    name: "SingleEstablishment",
    component: () =>
      import(
        /* webpackChunkName: "establishment" */ "../views/SingleEstablishment.vue"
      ),
  },

  {
    path: "/campaign-simulation",
    name: "CampaignSimulatioPrn",
    component: () => import("../views/WIP/CampaignSimulation.vue"),
  },
  {
    path: "/other-ton-cost-items",
    name: "OtherTonCostItems",
    component: () => import("../views/OtherTonCostItems.vue"),
  },
  {
    path: "/cost-items",
    name: "CostItems",
    component: () => import("../views/CostItems.vue"),
  },
  {
    path: "/direct-slice-cost-item/:id",
    name: "ListedCostItems",
    component: () => import("../views/ListedCostItems.vue"),
  },
  {
    path: "/stored-cost-item-group",
    name: "StoredCostItemGroup",
    component: () => import("../views/StoredCostItemGroup.vue"),
  },
  {
    path: "/stored-cost-item-group/:id",
    name: "SingleStoredCostItemGroup",
    component: () => import("../views/SingleStoredCostItemGroup.vue"),
  },
  {
    path: "/draft-activity-slice/:id",
    name: "SingleDraftActivitySlice",
    component: () => import("../views/SingleDraftActivitySlice.vue"),
  },

  {
    path: "/activity-slice/:id",
    name: "SingleActivitySlice",
    component: () => import("../views/SingleActivitySlice.vue"),
  },
  {
    path: "/activity-slice-cashflows/:id",
    name: "SingleActivitySliceCashflows",
    component: () => import("../views/SingleActivitySliceCashflows.vue"),
  },
  {
    path: "/activity-slice",
    name: "ActivitySlices",
    component: () => import("../views/ActivitySlices.vue"),
  },

  {
    path: "/engagement",
    name: "Engagements",
    component: () => import("../views/Engagements.vue"),
  },
  {
    path: "/draft-engagement",
    name: "DraftEngagements",
    props: (route) => ({
      establishmentId: parseInt(route.query.establishmentId),
      abstractCampaignId: route.query.abstractCampaignId,
    }),
    component: () => import("../views/DraftEngagements.vue"),
  },
  {
    path: "/draft-engagement-attachment-analysis",
    name: "DraftEngagementsAttachmentAnalysis",
    props: (route) => ({
      establishmentId: parseInt(route.query.establishmentId),
      abstractCampaignId: route.query.abstractCampaignId,
    }),
    component: () => import("../views/DraftEngagementsAttachmentAnalysis.vue"),
  },
  {
    path: "/draft-engagement-comparison/:id1/:id2",
    name: "DraftEngagementCompare",
    component: () => import("../views/DraftEngagementCompare"),
  },
  {
    path: "/financial-policy",
    name: "FinancialPolicy",
    component: () => import("../views/FinancialPolicy.vue"),
  },
  {
    path: "/abstract-campaign",
    name: "AbstractCampaigns",
    component: () => import("../views/AbstractCampaigns.vue"),
  },
  {
    path: "/abstract-campaign/:id",
    name: "Campaigns",
    component: () => import("../views/Campaigns.vue"),
  },
  {
    path: "/abstract-campaign/:abstractCampaignId/campaign-version-comparison/:id1/:id2",
    name: "CampaignVersionCompare",
    component: () => import("../views/CampaignVersionCompare"),
  },
  {
    path: "/abstract-campaign/:id1/campaign/:id2",
    name: "SingleCampaign",
    component: () => import("../views/SingleCampaign.vue"),
  },
  {
    path: "/abstract-campaign/:id1/campaign-simulation/:id2",
    name: "SingleCampaignSimulation",
    component: () => import("../views/SingleCampaignSimulation.vue"),
  },
  {
    path: "/abstract-campaign/:id1/campaign/:id2/modifiers",
    name: "SingleCampaignModifiers",
    component: () => import("../views/SingleCampaignModifiers.vue"),
  },
  {
    path: "/engagement/:id",
    name: "SingleEngagement",
    component: () => import("../views/SingleEngagement.vue"),
  },
  {
    path: "/draft-engagement/:id",
    name: "SingleDraftEngagement",
    component: () => import("../views/SingleDraftEngagement.vue"),
  },
  {
    path: "/",
    name: "Home",
    component: () => import("../views/Home.vue"),
  },
  {
    path: "/activity-and-species",
    name: "ActivityAndSpecies",
    component: () => import("../views/ActivityAndSpecies.vue"),
  },
  {
    path: "/activity/:id",
    name: "SingleActivity",
    component: () => import("../views/SingleActivity.vue"),
  },
  {
    path: "/species/:id",
    name: "SingleSpecies",
    component: () => import("../views/SingleSpecies.vue"),
  },
];

function isAdminUser() {
  let result = getDecodedTokenFromAuthHeader();
  if (result?.success) {
    let token = result?.token;
    if (token?.utype != ADMIN_ROLE) {
      return false;
    }
    return true;
  }
  return false;
}

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  await new Promise((r) => setTimeout(r, 100));

  if (!hasToLogin()) {
    if (to.path === "/login") {
      next({ name: "Home" });
    } else if (!isAdminUser() && to?.meta?.roles === ADMIN_ROLE) {
      next({ name: "Home" });
    } else {
      // If I go to the same route with diff query, its not a full load
      if (
        from.name === to.name &&
        JSON.stringify(to.query) !== JSON.stringify(from.query)
      ) {
        to.meta.isFullLoad = false;
      } else {
        to.meta.isFullLoad = true;
      }
      next();
    }
  } else {
    if (to.path === "/login") {
      next();
    } else {
      next({ name: "Login" });
    }
  }
});
export default router;
