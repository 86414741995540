import { getRequest } from "./serverCall";

// Move this to the top level
const FILTER_MAP = {
  actuals: "actual",
  forecast: "forecast",
  all: "all",
};

async function retrieveMultiResourceUtilization(sliceIds) {
  const actualsFilter = localStorage.getItem("actualFilter") || "all";

  try {
    let response = await getRequest(
      `/module/get/resource_manager?ids_list=${sliceIds.join(
        ","
      )}&actual_filter=${FILTER_MAP[actualsFilter] || "all"}`,
      true
    );
    return response;
  } catch (error) {
    return {
      success: false,
      data: {
        type: "error",
        text: "No fue posible mostrar la utilización de recursos",
      },
    };
  }
}

async function retrieveDraftMultiResourceUtilization(sliceIds) {
  try {
    let response = await getRequest(
      "/module/get/draft_resource_manager?ids_list=" + sliceIds.join(","),
      true
    );
    return response;
  } catch (error) {
    return {
      success: false,
      data: {
        type: "error",
        text: "No fue posible mostrar la utilización de recursos",
      },
    };
  }
}

async function retrieveCampaignMultiResourceUtilization(
  campaignId,
  options = {}
) {
  const actualsFilter = localStorage.getItem("actualFilter") || "all";

  try {
    const params = new URLSearchParams();
    params.append("actual_filter", FILTER_MAP[actualsFilter] || "all");

    // Add optional filters if provided
    if (options.speciesId) params.append("species_id", options.speciesId);
    if (options.regionId) params.append("region_id", options.regionId);
    if (options.activityIds) params.append("activity_ids", options.activityIds);

    const endpoint = `/module/get/campaign_resource_manager?campaign_id=${campaignId}&${params.toString()}`;

    let response = await getRequest(endpoint, true);
    return response;
  } catch (error) {
    return {
      success: false,
      data: {
        type: "error",
        text: "No fue posible mostrar la utilización de recursos",
      },
    };
  }
}

export {
  retrieveCampaignMultiResourceUtilization,
  retrieveMultiResourceUtilization,
  retrieveDraftMultiResourceUtilization,
};
