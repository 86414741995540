import { getRequest } from "./serverCall";
import { keysToCamel } from "./objectUtils";
import { roundToX, parseToLocaleString } from "../managrx/utils.js";

import numbro from "numbro";

let standardNumbroFormat = { mantissa: 2, average: true };

let URL = getURL();

function getURL() {
  return process.env.VUE_APP_SIGMA_BACKEND_URL;
}
function findMaxInvestmentValue(rows) {
  let profits = rows?.find((el) => el?.category == "Saldo Acumulado");
  let profitsHistory = Object.assign({}, profits);
  delete profitsHistory?.["category"];
  delete profitsHistory?.["total"];
  delete profitsHistory?.["total/ha"];
  profitsHistory = Object.values(profitsHistory);
  profitsHistory = profitsHistory.map((el) => parseStringToNumber(el));

  let minValue = Math.min(...profitsHistory);

  return minValue;
}
function parseStringToNumber(el) {
  return parseFloat(el?.replace(regexThousandsSeparator(), ""));
}
function regexDecimalsSeparator() {
  // Use ?.toLocaleString('en-US',{timeZone:'America/Argentina/Buenos_Aires'}) in a test value to get the locale decimals separator as regex exp
  let testNumber = 0.5?.toLocaleString("en-US", {
    timeZone: "America/Argentina/Buenos_Aires",
  });
  let decimalSeparator = testNumber[1];
  return new RegExp(decimalSeparator, "g");
}
function regexThousandsSeparator() {
  // Use ?.toLocaleString('en-US',{timeZone:'America/Argentina/Buenos_Aires'}) in a test value to get the locale thousands separator as regex exp
  let testNumber = 1000?.toLocaleString("en-US", {
    timeZone: "America/Argentina/Buenos_Aires",
  });
  let thousandsSeparator = testNumber[1];
  return new RegExp(thousandsSeparator, "g");
}
function validateRequestObject(obj, validate) {
  for (let index = 0; index < validate.length; index++) {
    const valKey = validate[index];
    if (obj[valKey] === null || obj[valKey] === 0 || obj[valKey] === "") {
      return { success: false, key: valKey };
    }
  }
  return { success: true };
}

function makeSingleDraftActivitySliceInfoGrid(
  singleDraftActivitySliceCashflow,
  singleDraftActivitySliceSummary
) {
  let rentCostByHectare = singleDraftActivitySliceCashflow?.rows?.find(
    (el) => el.category == "Arrendamientos"
  )?.["total/ha"];
  let fixedSharedCropCostByHectare =
    singleDraftActivitySliceCashflow?.rows?.find(
      (el) => el.category == "Aparcería Fija"
    )?.["total/ha"];

  let variableSharedCropCostByHectare =
    singleDraftActivitySliceCashflow?.rows?.find(
      (el) => el.category == "Aparcería Variable"
    )?.["total/ha"];

  let rentAndSharedCropp =
    parseStringToNumber(rentCostByHectare) +
    parseStringToNumber(fixedSharedCropCostByHectare) +
    parseStringToNumber(variableSharedCropCostByHectare);

  let totalIncomeByHectare = singleDraftActivitySliceCashflow?.rows?.find(
    (el) => el.category == "Total Ingresos"
  )?.["total/ha"];

  let totalIncomeUSD = singleDraftActivitySliceCashflow?.rows?.find(
    (el) => el.category == "Total Ingresos"
  )?.["total"];

  let totalProfits = singleDraftActivitySliceCashflow?.rows?.find(
    (el) => el.category == "Resultado Directo"
  )?.["total"];

  let totalProfitsByHectares = singleDraftActivitySliceCashflow?.rows?.find(
    (el) => el.category == "Resultado Directo"
  )?.["total/ha"];

  let autofinanciablesByHectare = singleDraftActivitySliceCashflow?.rows?.find(
    (el) => el.category == "Autofinanciables"
  )?.["total/ha"];
  let nonautofinanciableByHectare =
    singleDraftActivitySliceCashflow?.rows?.find(
      (el) => el.category == "No Autofinanciables"
    )?.["total/ha"];
  
 

  let nonautofinanciableCost = parseStringToNumber(nonautofinanciableByHectare);
  let totalCosts =
    parseStringToNumber(autofinanciablesByHectare) + nonautofinanciableCost;
  let netIncomeOverExpense =
    (parseStringToNumber(totalIncomeByHectare) -
      parseStringToNumber(autofinanciablesByHectare)) /
    nonautofinanciableCost;
  let grossIncomeOverExpense =
    parseStringToNumber(totalIncomeByHectare) / totalCosts;
  let maxInvestment = findMaxInvestmentValue(
    singleDraftActivitySliceCashflow?.rows
  );

  let tdmxi = parseStringToNumber(totalProfits) / maxInvestment;

  // Red color is used when a division by 0 is made
  let tdmxiColorStatus = maxInvestment != 0 ? "" : "red--text";
  let tdmxiToolTipText =
    maxInvestment != 0
      ? "Tasa de Máxima Inversión (%)"
      : "Máxima Inversión no puede ser 0";
  let grossIncomeOverExpenseColorStatus = totalCosts != 0 ? "" : "red--text";
  let grossIncomeOverExpenseToolTipText =
    totalCosts != 0 ? "Ingreso Bruto / Gasto" : "Costos Totales no puede ser 0";
  let netIncomeOverExpenseColorStatus =
    nonautofinanciableCost != 0 ? "" : "red--text";
  let netIncomeOverExpenseToolTipText =
    nonautofinanciableCost != 0
      ? "Ingreso Neto/Gasto (%)"
      : "No Autofinanciables no puede ser 0";

  return [
    [
      {
        label: "Resultado Directo (usd/ha)",
        description: "Resultado Directo (Resultado) (usd/ha)",
        value: numbro(totalProfitsByHectares).format(standardNumbroFormat),
        valueClass: "text-darken-2 h3 display-1",
        variant: "primary",
        labelIcon: "mdi-chart-line",
      },
      {
        label: "Resultado Directo (usd)",
        description: "Resultado Directo (Resultado) (usd)",
        value: numbro(totalProfits).format(standardNumbroFormat),
        valueClass: "text-darken-2 h3 display-1",
        variant: "primary",
        labelIcon: "mdi-chart-line",
      },
      {
        label: "Alquiler y Aparcerías (usd/ha)",
        description: "Alquiler y Aparcerías (usd/ha)",
        value: parseToLocaleString(rentAndSharedCropp),
        valueClass: "text-darken-2 h3 display-1",
        variant: "primary",
        labelIcon: "mdi-barn",
      },
      {
        label: "Autofinanciables (usd/ha)",
        description: "Egresos Autofinanciables (usd/ha)",
        value: parseToLocaleString(autofinanciablesByHectare),
        valueClass: "text-darken-2 h3 display-1",
        variant: "primary",
        labelIcon: "mdi-briefcase",
      },
      {
        label: "NoAutofinanciables (usd/ha)",
        description: "NoAutofinanciables (usd/ha)",
        value: parseToLocaleString(nonautofinanciableByHectare),
        valueClass: "text-darken-2 h3 display-1",
        variant: "primary",
        labelIcon: "mdi-seed",
      },
      {
        label: "Ingreso Neto (usd/ha)",
        description:
          "(Ingreso Bruto Propio + Aparcerias Netas Equivalentes - Gastos de Venta) / Hectáreas Asignadas",
        value: numbro(totalIncomeByHectare).format(standardNumbroFormat),
        valueClass: "text-darken-2 h3 display-1",
        variant: "primary",
        labelIcon: "mdi-currency-usd",
      },
      {
        label: "Ingreso Neto (usd)",
        description:
          "Ingreso Bruto Propio + Aparcerias Netas Equivalentes - Gastos de Venta",
        value: numbro(totalIncomeUSD).format(standardNumbroFormat),
        valueClass: "text-darken-2 h3 display-1",
        variant: "primary",
        labelIcon: "mdi-currency-usd",
      },
      {
        label: "Ingreso Neto/Gasto (%)",
        description: netIncomeOverExpenseToolTipText,
        value: roundToX(netIncomeOverExpense, 2)?.toLocaleString("en-US", {
          timeZone: "America/Argentina/Buenos_Aires",
        }),
        valueClass: `${netIncomeOverExpenseColorStatus} text-darken-2 h3 display-1 `,
        variant: "primary",
        labelIcon: "mdi-percent",
      },
      {
        label: "Ingreso/Gasto (%)",
        description: grossIncomeOverExpenseToolTipText,
        value: roundToX(grossIncomeOverExpense, 1)?.toLocaleString("en-US", {
          timeZone: "America/Argentina/Buenos_Aires",
        }),
        valueClass: `${grossIncomeOverExpenseColorStatus} text-darken-2 h3 display-1`,
        variant: "primary",
        labelIcon: "mdi-percent-outline",
      },
      {
        label: "Máxima Inversión (usd)",
        description: "Máxima Inversión (usd)",
        value: numbro(maxInvestment).format(standardNumbroFormat),
        valueClass: "text-darken-2 h3 display-1 ",
        variant: "primary",
        labelIcon: "mdi-align-vertical-top",
      },
      {
        label: "Tasa de Máxima Inversión (%)",
        description: tdmxiToolTipText,
        value: parseToLocaleString(roundToX(tdmxi * -100, 2)),
        valueClass: `${tdmxiColorStatus} text-darken-2 h3 display-1`,
        variant: "primary",
        labelIcon: "mdi-sack-percent",
      },
      {
        label: "Precio Neto (usd/qq)",
        description:
          "Precio descontando gastos de venta, flete, acondicionamiento y embolsado",
        value: parseToLocaleString(
          roundToX(singleDraftActivitySliceSummary.netPrice, 2)
        ),
        valueClass: "text-darken-2 h3 display-1 ",
        variant: "primary",
        labelIcon: "mdi-currency-usd",
      },
      {
        label: "Indifference (qq/ha)",
        description:
          "Precio al que el Ingreso Neto es 0",
        value: parseToLocaleString(
          roundToX(singleDraftActivitySliceSummary?.indifferenceQqha*10, 2)
        ),
        valueClass: "text-darken-2 h3 display-1 ",
        variant: "primary",
        labelIcon: "mdi-scale",
      },
    ],
  ];
}

function makeSingleActivitySliceInfoGrid(
  singleActivitySliceCashflow,
  singleActivitySliceSummary
) {
  let rentCostByHectare = singleActivitySliceCashflow?.rows?.find(
    (el) => el?.category == "Arrendamientos"
  )?.["total/ha"];
  let fixedSharedCropCostByHectare = singleActivitySliceCashflow?.rows?.find(
    (el) => el?.category == "Aparcería Fija"
  )?.["total/ha"];

  let variableSharedCropCostByHectare = singleActivitySliceCashflow?.rows?.find(
    (el) => el?.category == "Aparcería Variable"
  )?.["total/ha"];

  let totalIncomeByHectare = singleActivitySliceCashflow?.rows?.find(
    (el) => el?.category == "Total Ingresos"
  )?.["total/ha"];

  let totalIncomeUSD = singleActivitySliceCashflow?.rows?.find(
    (el) => el?.category == "Total Ingresos"
  )?.["total"];
  let totalProfitsByHectares = singleActivitySliceCashflow?.rows?.find(
    (el) => el?.category == "Resultado Directo"
  )?.["total/ha"];

  let totalProfits = singleActivitySliceCashflow?.rows?.find(
    (el) => el?.category == "Resultado Directo"
  )?.["total"];


  let rentAndSharedCropp =
    parseStringToNumber(rentCostByHectare) +
    parseStringToNumber(fixedSharedCropCostByHectare) +
    parseStringToNumber(variableSharedCropCostByHectare);

  let autofinanciablesByHectare = singleActivitySliceCashflow?.rows?.find(
    (el) => el?.category == "Autofinanciables"
  )?.["total/ha"];
  let nonautofinanciableByHectare = singleActivitySliceCashflow?.rows?.find(
    (el) => el?.category == "No Autofinanciables"
  )?.["total/ha"];

  let nonautofinanciableCost = parseStringToNumber(nonautofinanciableByHectare);
  let totalCosts =
    parseStringToNumber(autofinanciablesByHectare) + nonautofinanciableCost;

  let netIncomeOverExpense =
    (parseStringToNumber(totalIncomeByHectare) -
      parseStringToNumber(autofinanciablesByHectare)) /
    nonautofinanciableCost;
  let grossIncomeOverExpense =
    parseStringToNumber(totalIncomeByHectare) / totalCosts;
  let maxInvestment = findMaxInvestmentValue(singleActivitySliceCashflow?.rows);
  let tdmxi = parseStringToNumber(totalProfits) / maxInvestment;
  // Red color is used when a division by 0 is made on tdmxi
  let tdmxiColorStatus = maxInvestment != 0 ? "" : "red--text";
  let tdmxiToolTipText =
    maxInvestment != 0
      ? "Tasa de Máxima Inversión (%)"
      : "Máxima Inversión no puede ser 0";
  let grossIncomeOverExpenseColorStatus = totalCosts != 0 ? "" : "red--text";
  let grossIncomeOverExpenseToolTipText =
    totalCosts != 0 ? "Ingreso Bruto / Gasto" : "Costos Totales no puede ser 0";
  let netIncomeOverExpenseColorStatus =
    nonautofinanciableCost != 0 ? "" : "red--text";
  let netIncomeOverExpenseToolTipText =
    nonautofinanciableCost != 0
      ? "Ingreso Neto/Gasto (%)"
      : "No Autofinanciables no puede ser 0";
  return [
    [
      {
        label: "Resultado Directo (usd/ha)",
        description: "Resultado Directo (Resultado) (usd/ha)",
        value: numbro(totalProfitsByHectares)?.format(standardNumbroFormat),
        valueClass: "text-darken-2 h3 display-1",
        variant: "primary",
        labelIcon: "mdi-chart-line",
      },
      {
        label: "Resultado Directo (usd)",
        description: "Resultado Directo (Resultado) (usd)",
        value: numbro(totalProfits)?.format(standardNumbroFormat),
        valueClass: "text-darken-2 h3 display-1",
        variant: "primary",
        labelIcon: "mdi-chart-line",
      },
      {
        label: "Alquiler y Aparcerías (usd/ha)",
        description: "Alquiler y Aparcerías (usd/ha)",
        value: parseToLocaleString(rentAndSharedCropp),
        valueClass: "text-darken-2 h3 display-1",
        variant: "primary",
        labelIcon: "mdi-barn",
      },
      {
        label: "Autofinanciables (usd/ha)",
        description: "Egresos Autofinanciables (usd/ha)",
        value: parseToLocaleString(autofinanciablesByHectare),
        valueClass: "text-darken-2 h3 display-1",
        variant: "primary",
        labelIcon: "mdi-briefcase",
      },
      {
        label: "NoAutofinanciables (usd/ha)",
        description: "NoAutofinanciables (usd/ha)",
        value: parseToLocaleString(nonautofinanciableByHectare),
        valueClass: "text-darken-2 h3 display-1",
        variant: "primary",
        labelIcon: "mdi-seed",
      },
      {
        label: "Ingreso Neto (usd/ha)",
        description:
          "(Ingreso Bruto Propio + Aparcerias Netas Equivalentes - Gastos de Venta) / Hectáreas Asignadas",
        value: numbro(totalIncomeByHectare)?.format(standardNumbroFormat),
        valueClass: "text-darken-2 h3 display-1",
        variant: "primary",
        labelIcon: "mdi-currency-usd",
      },
      {
        label: "Ingreso Neto (usd)",
        description:
          "Ingreso Bruto Propio + Aparcerias Netas Equivalentes - Gastos de Venta",
        value: numbro(totalIncomeUSD)?.format(standardNumbroFormat),
        valueClass: "text-darken-2 h3 display-1",
        variant: "primary",
        labelIcon: "mdi-currency-usd",
      },
      {
        label: "Ingreso Neto/Gasto (%)",
        description: netIncomeOverExpenseToolTipText,
        value: parseToLocaleString(roundToX(netIncomeOverExpense, 2)),
        valueClass: `${netIncomeOverExpenseColorStatus} text-darken-2 h3 display-1 `,
        variant: "primary",
        labelIcon: "mdi-percent",
      },
      {
        label: "Ingreso/Gasto (%)",
        description: grossIncomeOverExpenseToolTipText,
        value: parseToLocaleString(roundToX(grossIncomeOverExpense, 1)),
        valueClass: `${grossIncomeOverExpenseColorStatus} text-darken-2 h3 display-1`,
        variant: "primary",
        labelIcon: "mdi-percent-outline",
      },
      {
        label: "Máxima Inversión (usd)",
        description: "Máxima Inversión (usd)",
        value: numbro(maxInvestment)?.format(standardNumbroFormat),
        valueClass: "text-darken-2 h3 display-1 ",
        variant: "primary",
        labelIcon: "mdi-align-vertical-top",
      },
      {
        label: "Tasa de Máxima Inversión (%)",
        description: tdmxiToolTipText,
        value: parseToLocaleString(roundToX(tdmxi * -100, 2)),
        valueClass: `${tdmxiColorStatus} text-darken-2 h3 display-1`,
        variant: "primary",
        labelIcon: "mdi-sack-percent",
      },
      {
        label: "Precio Neto (usd/qq)",
        description:
          "Precio descontando gastos de venta, flete, acondicionamiento y embolsado",
        value: parseToLocaleString(
          roundToX(singleActivitySliceSummary?.netPrice, 2)
        ),
        valueClass: "text-darken-2 h3 display-1 ",
        variant: "primary",
        labelIcon: "mdi-currency-usd",
      },
      {
        label: "Indifference (qq/ha)",
        description:
          "Precio al que el Ingreso Neto es 0",
        value: parseToLocaleString(
          roundToX(singleActivitySliceSummary?.indifferenceQqha*10, 2)
        ),
        valueClass: "text-darken-2 h3 display-1 ",
        variant: "primary",
        labelIcon: "mdi-scale",
      },
    ],
  ];
}

function makeCampaignInfoGrid(campaignKpisInfo, mantissa = 2) {
  let unitToIcon = {
    "%": "mdi-sack-percent",
    usd: "mdi-align-vertical-top",
    "usd/ha": "mdi-currency-usd",
    ha: "mdi-barn",
    ton: "mdi-weight",
    "qq/ha": "mdi-sprout",
    qq: "mdi-sprout-outline",
  };
  return [
    campaignKpisInfo.map((el) => {
      return {
        label: `${el?.key ? el?.key : ""} (${el?.unit})`,
        description: `${el?.key ? el?.key : ""} (${el?.unit})`,
        value: numbro(el?.value ? el?.value : 0)?.format({
          mantissa: mantissa,
          average: true,
        }),
        valueClass: `text-darken h4 display-1`,
        variant: "primary",
        labelIcon: unitToIcon[el?.unit ? el?.unit : "usd"],
      };
    }),
  ];
}
function makeCampaignSimulationInfoGrid(
  campaignKpisInfo,
  campaignSimulationKpisInfo
) {
  console.log(typeof campaignKpisInfo, campaignSimulationKpisInfo);
  let unitToIcon = {
    "%": "mdi-sack-percent",
    usd: "mdi-align-vertical-top",
    "usd/ha": "mdi-currency-usd",
    ha: "mdi-barn",
    ton: "mdi-weight",
  };
  let parsedCampaignSimulationKpisInfo = [];
  let parsedCampaignKpisInfo = [];
  for (
    let i = 0;
    i < Object.keys(campaignKpisInfo).length &&
    i < Object.keys(campaignSimulationKpisInfo).length;
    i++
  ) {
    let kpi = campaignKpisInfo[i];
    let simulationKpi = campaignSimulationKpisInfo[i];
    let valuePct = 0;
    if (kpi?.value < 0) {
      valuePct = roundToX(
        (simulationKpi?.value / kpi?.value - 1) * -1 * 100,
        2
      );
    } else {
      valuePct = roundToX((simulationKpi?.value / kpi?.value - 1) * 100, 2);
    }
    parsedCampaignKpisInfo?.push({
      label: `${kpi.key} (${kpi?.unit})`,
      description: `${kpi.key} (${kpi?.unit})`,
      value: numbro(kpi.value)?.format(standardNumbroFormat),
      valuePct: valuePct,
      valueClass: `text-darken h4 display-1`,
      variant: "primary",
      labelIcon: unitToIcon[kpi?.unit],
    });

    parsedCampaignSimulationKpisInfo?.push({
      label: `${simulationKpi.key} (${simulationKpi?.unit})`,
      description: `${simulationKpi.key} (${simulationKpi?.unit})`,
      value: numbro(simulationKpi.value)?.format(standardNumbroFormat),
      valueClass: `text-darken h4 display-1`,
      variant: "primary",
      labelIcon: unitToIcon[kpi?.unit],
    });
  }
  return [parsedCampaignKpisInfo, parsedCampaignSimulationKpisInfo];
}
function kRowsToItems(rows, keys, dontCamel) {
  // Converts a an array of arrays into objects by providing indexed
  // name for fields in `keys`.
  let items = [];

  for (let i = 0; i < rows.length; i++) {
    let item = {};
    const row = rows[i];

    for (let k = 0; k < keys.length; k++) {
      const key = keys[k];
      item[key] = row[k];
    }
    if (!dontCamel) {
      items.push(keysToCamel(item));
    } else {
      items.push(item);
    }
  }
  return items;
}

async function getASByID(activitySliceId) {
  // Function used to retrieve all financial and economic data related to a single activity slice
  let response = await getRequest("/activity_slice/" + activitySliceId, false);
  if (!response.success) {
    return response;
  }
  let activitySlice = { ...response.data };

  // TODO -> Fully deprecate forwardPrice when Juan finished refactoring cashflows.

  let summaryResponse = await getRequest(
    `/views/activity_slice_summary?q=activity_slice_id:${activitySlice.id}`,
    true
  );
  if (!summaryResponse.success) {
    return summaryResponse;
  }

  let summary = kRowsToItems(
    summaryResponse.data?.rows,
    summaryResponse.data.keys
  )[0];

  let [
    activityResponse,
    engResponse,
    engSummaryResponse,
    harvestEventsResponse,
    campaignResponse,
    establishmentResponse,
    harvestSummaryResponse,
    assignedSliceCostDataResponse,
    rentsSummary,
    rentPaymentPlanResponse,
    rentFixedPaymentResponse,
    rentBonusResponse,
    assignedTonCostsResponse,
    variableShareCropResponse,
  ] = await Promise.all([
    getRequest("/activity/" + summary.activityId, false),
    getRequest("/engagement/" + summary.engagementId, false),
    getRequest(
      "/views/enriched_campaign_engagements?q=engagement_id:" +
        summary.engagementId,
      true
    ),
    getRequest(`/harvest_event/?q=activity_slice_id:${activitySliceId}`),
    getRequest("/campaign/" + summary.campaignId, false),
    getRequest("/establishment/" + summary.establishmentId, false),
    getRequest(
      `/views/harvest_costs_by_activity_slice?q=activity_slice_id:${activitySlice.id}`,
      true
    ),
    getRequest(
      `/views/assigned_direct_slice_costs_by_activity_slice?q=activity_slice_id:${activitySlice.id}`,
      true
    ),
    getRequest(
      `/views/rent_costs_by_activity_slice?q=activity_slice_id:${activitySlice.id}`,
      true
    ),
    getRequest(
      `/views/rent_payment_plan_by_activity_slice?q=activity_slice_id:${activitySlice.id}`,
      false
    ),
    getRequest(
      `/views/rent_fixed_payment_by_activity_slice?q=activity_slice_id:${activitySlice.id}`,
      false
    ),
    getRequest(
      `/engagement_rent_bonus?q=engagement_id:${summary.engagementId},activity_id:${summary.activityId}`,
      false
    ),
    getRequest(
      `/views/assigned_ton_costs_by_activity_slice?q=activity_slice_id:${activitySlice.id}`,
      true
    ),

    getRequest(
      "/variable_share_crop_schedule?q=" +
        "activity_slice_id:" +
        activitySliceId
    ),
  ]);
  let badResponseArray = [];
  for (const response of [
    activityResponse,
    engResponse,
    engSummaryResponse,
    harvestEventsResponse,
    campaignResponse,
    establishmentResponse,
    harvestSummaryResponse,
    assignedSliceCostDataResponse,
    rentsSummary,
    rentPaymentPlanResponse,
    rentFixedPaymentResponse,
    rentBonusResponse,
    assignedTonCostsResponse,
    variableShareCropResponse,
  ]) {
    if (!response.success) {
      badResponseArray.push(response);
    }
  }
  if (badResponseArray.length > 0) {
    return { success: false, isArray: true, data: badResponseArray };
  }
  let activity = { ...activityResponse.data };
  let engagement = { ...engResponse.data };
  let engagementSummary = kRowsToItems(
    engSummaryResponse.data?.rows,
    engSummaryResponse.data?.keys
  )[0];
  let harvestSummaryData = { ...harvestSummaryResponse.data };

  let harvestEvents = [...harvestEventsResponse.data];
  let harvestSummary = kRowsToItems(
    harvestSummaryData?.rows,
    harvestSummaryData.keys
  )[0];

  let campaign = { ...campaignResponse.data };
  let establishment = { ...establishmentResponse.data };

  let assignedSliceCostData = assignedSliceCostDataResponse.data;
  let assignedSCostItems = kRowsToItems(
    assignedSliceCostData?.rows,
    assignedSliceCostData.keys
  );

  assignedSCostItems = assignedSCostItems.sort(
    (a, b) => a.costItemNaturalOrder - b.costItemNaturalOrder
  );

  let rentSummary = kRowsToItems(
    rentsSummary.data?.rows,
    rentsSummary.data.keys
  )[0];

  let assignedTonCosts = kRowsToItems(
    assignedTonCostsResponse.data?.rows,
    assignedTonCostsResponse.data.keys
  );

  let rentPaymentPlan = kRowsToItems(
    rentPaymentPlanResponse.data?.rows,
    rentPaymentPlanResponse.data.keys
  ).sort((a, b) => a.date - b.date);

  let rentFixedPayment = kRowsToItems(
    rentFixedPaymentResponse.data?.rows,
    rentFixedPaymentResponse.data.keys
  ).sort((a, b) => a.date - b.date);

  let rentBonus = rentBonusResponse?.data?.[0]
    ? rentBonusResponse.data[0]
    : null;

  let schedule = variableShareCropResponse.data;

  engagement.campaign = campaign;
  engagement.establishment = establishment;
  engagement.summary = engagementSummary;
  activitySlice.activity = activity;
  activitySlice.engagement = engagement;
  activitySlice.summary = summary;
  activitySlice.rentSummary = rentSummary;
  activitySlice.rentPaymentPlan = {
    keys: Object.keys(rentPaymentPlan?.[0] ?? {}),
    items: rentPaymentPlan,
  };
  activitySlice.rentFixedPayment = {
    keys: Object.keys(rentFixedPayment?.[0] ?? {}),
    items: rentFixedPayment,
  };
  activitySlice.rentBonus = rentBonus;

  activitySlice.harvestEvents = harvestEvents;
  activitySlice.harvestSummary = harvestSummary;

  activitySlice.assignedSCostItems = assignedSCostItems;
  activitySlice.assignedTonCosts = assignedTonCosts;

  activitySlice.variableShareCropSchedule = schedule;

  return { success: true, data: activitySlice };
}

async function getDraftASByID(draftActivitySliceId) {
  // Function used to retrieve all financial and economic data related to a single activity slice
  let response = await getRequest(
    "/draft_activity_slice/" + draftActivitySliceId,
    false
  );
  if (!response.success) {
    return response;
  }
  let draftActivitySlice = { ...response.data };
  // TODO -> Fully deprecate forwardPrice when Juan finished refactoring cashflows.

  let summaryResponse = await getRequest(
    `/views/draft_activity_slice_summary?q=draft_activity_slice_id:${draftActivitySliceId}`,
    true
  );
  if (!summaryResponse.success) {
    return summaryResponse;
  }

  let summary = kRowsToItems(
    summaryResponse.data?.rows,
    summaryResponse.data.keys
  )[0];

  let [
    activityResponse,
    engResponse,
    establishmentResponse,
    harvestEventsResponse,
    harvestSummaryResponse,
    assignedSliceCostDataResponse,
    rentsSummary,
    rentPaymentPlanResponse,
    rentFixedPaymentResponse,
    rentBonusResponse,
    assignedTonCostsResponse,
    variableShareCropResponse,
  ] = await Promise.all([
    getRequest("/activity/" + summary.activityId, false),
    getRequest("/draft_engagement/" + summary.draftEngagementId, false),

    getRequest("/establishment/" + summary.establishmentId, false),
    getRequest(
      `/draft_harvest_event/?q=draft_activity_slice_id:${draftActivitySliceId}`
    ),
    getRequest(
      `/views/draft_harvest_costs_by_activity_slice?q=draft_activity_slice_id:${draftActivitySliceId}`,
      true
    ),
    getRequest(
      `/views/draft_assigned_direct_slice_costs_by_activity_slice?q=draft_activity_slice_id:${draftActivitySliceId}`,
      true
    ),
    getRequest(
      `/views/draft_rent_costs_by_activity_slice?q=draft_activity_slice_id:${draftActivitySliceId}`,
      true
    ),
    getRequest(
      `/views/draft_rent_payment_plan_by_activity_slice?q=draft_activity_slice_id:${draftActivitySliceId}`,
      false
    ),
    getRequest(
      `/views/draft_rent_fixed_payment_by_activity_slice?q=draft_activity_slice_id:${draftActivitySliceId}`,
      false
    ),
    getRequest(
      `/draft_engagement_rent_bonus?q=draft_engagement_id:${summary.draftEngagementId},activity_id:${summary.activityId}`,
      false
    ),
    getRequest(
      `/views/draft_assigned_ton_costs_by_activity_slice?q=draft_activity_slice_id:${draftActivitySliceId}`,
      true
    ),

    getRequest(
      "/draft_variable_share_crop_schedule?q=" +
        "draft_activity_slice_id:" +
        draftActivitySliceId
    ),
  ]);
  let badResponseArray = [];
  for (const response of [
    activityResponse,
    engResponse,
    establishmentResponse,
    harvestEventsResponse,
    harvestSummaryResponse,
    assignedSliceCostDataResponse,
    rentsSummary,
    rentPaymentPlanResponse,
    rentFixedPaymentResponse,
    rentBonusResponse,
    assignedTonCostsResponse,
    variableShareCropResponse,
  ]) {
    if (!response.success) {
      badResponseArray.push(response);
    }
  }
  if (badResponseArray.length > 0) {
    return { success: false, isArray: true, data: badResponseArray };
  }

  let activity = { ...activityResponse.data };
  let engagement = { ...engResponse.data };

  let harvestSummaryData = { ...harvestSummaryResponse.data };

  let harvestEvents = [...harvestEventsResponse.data];
  let harvestSummary = kRowsToItems(
    harvestSummaryData?.rows,
    harvestSummaryData.keys
  )[0];

  let establishment = { ...establishmentResponse.data };

  let assignedSliceCostData = assignedSliceCostDataResponse.data;
  let assignedSCostItems = kRowsToItems(
    assignedSliceCostData?.rows,
    assignedSliceCostData.keys
  );

  assignedSCostItems = assignedSCostItems.sort(
    (a, b) => a.costItemNaturalOrder - b.costItemNaturalOrder
  );

  let rentSummary = kRowsToItems(
    rentsSummary.data?.rows,
    rentsSummary.data.keys
  )[0];

  let assignedTonCosts = kRowsToItems(
    assignedTonCostsResponse.data?.rows,
    assignedTonCostsResponse.data.keys
  );

  let rentPaymentPlan = kRowsToItems(
    rentPaymentPlanResponse.data?.rows,
    rentPaymentPlanResponse.data.keys
  );

  let rentFixedPayment = kRowsToItems(
    rentFixedPaymentResponse.data?.rows,
    rentFixedPaymentResponse.data.keys
  ).sort((a, b) => a.date - b.date);

  let rentBonus = rentBonusResponse?.data?.[0]
    ? rentBonusResponse.data[0]
    : null;

  let schedule = variableShareCropResponse?.data;

  engagement.establishment = establishment;

  draftActivitySlice.activity = activity;
  draftActivitySlice.engagement = engagement;
  draftActivitySlice.summary = summary;
  draftActivitySlice.rentSummary = rentSummary;
  draftActivitySlice.rentPaymentPlan = {
    keys: Object.keys(rentPaymentPlan?.[0] ?? {}),
    items: rentPaymentPlan,
  };
  draftActivitySlice.rentFixedPayment = {
    keys: Object.keys(rentFixedPayment?.[0] ?? {}),
    items: rentFixedPayment,
  };
  draftActivitySlice.rentBonus = rentBonus;

  draftActivitySlice.harvestEvents = harvestEvents;
  draftActivitySlice.harvestSummary = harvestSummary;

  draftActivitySlice.assignedSCostItems = assignedSCostItems;
  draftActivitySlice.assignedTonCosts = assignedTonCosts;

  draftActivitySlice.variableShareCropSchedule = schedule;

  return { success: true, data: draftActivitySlice };
}

async function updateSingleActivitySliceCache(activitySlice, key) {
  // Function to return an updated single activity slice object
  // key is used to determine what part of the object needs updating
  let validKeys = [
    "assignedSCostItems",
    "assignedTonCosts",
    "summary",
    "rents",
    "variableShareCrop",
    "harvestEvents",
  ];

  let hasError = false;
  let errorDataArray = [];
  if (!validKeys.includes(key)) {
    return {
      success: false,
      type: "error",
      text: `${key} not not in keys. Cant refresh cache.`,
    };
  }
  // Assigned direct slice costs
  if (key === "assignedSCostItems") {
    let assignedSliceCostDataResponse = await getRequest(
      `/views/assigned_direct_slice_costs_by_activity_slice?q=activity_slice_id:${activitySlice.id}`,
      true
    );
    if (!assignedSliceCostDataResponse.success) {
      hasError = true;
      errorDataArray.push(assignedSliceCostDataResponse);
    } else {
      let assignedSliceCostData = assignedSliceCostDataResponse.data;
      let assignedSCostItems = kRowsToItems(
        assignedSliceCostData?.rows,
        assignedSliceCostData.keys
      );
      activitySlice.assignedSCostItems = assignedSCostItems;

      let summaryResponse = await getRequest(
        `/views/activity_slice_summary?q=activity_slice_id:${activitySlice.id}`,
        true
      );
      if (!summaryResponse.success) {
        hasError = true;
        errorDataArray.push(summaryResponse);
      } else {
        let summary = kRowsToItems(
          summaryResponse.data?.rows,
          summaryResponse.data.keys
        )[0];

        activitySlice.summary = summary;
      }
    }
  }
  // Summary statistics
  if (key === "summary") {
    // Update the summary and activity_slice fields
    let summaryResponse = await getRequest(
      `/views/activity_slice_summary?q=activity_slice_id:${activitySlice.id}`,
      true
    );
    if (!summaryResponse.success) {
      hasError = true;
      errorDataArray.push(summaryResponse);
    } else {
      let actSliResponse = await getRequest(
        `/activity_slice/${activitySlice.id}`
      );
      if (!actSliResponse.success) {
        hasError = true;
        errorDataArray.push(actSliResponse);
      } else {
        let updatableKeys = [
          "assignedHectares",
          "forwardYield",
          "minHarvestCost",
          "maxHarvestCost",
          "harvestPercentCost",
          "rental_total_qq",
          "rentQqActivity",
          "freightCostTn",
          "isActualFreightCostTn",
          "isActualHarvestCost",
          "fixedSharecroppingPercent",
        ];

        for (let i = 0; i < updatableKeys.length; i++) {
          const key = updatableKeys[i];
          activitySlice[key] = actSliResponse.data[key];
        }

        let summary = kRowsToItems(
          summaryResponse.data?.rows,
          summaryResponse.data.keys
        )[0];

        activitySlice.summary = summary;
      }
    }
  }
  // Rents summary
  if (key === "rents") {
    let as = await getRequest(`/activity_slice/${activitySlice.id}`);
    if (!as.success) {
      hasError = true;
      errorDataArray.push(as);
    } else {
      let activitySliceData = as.data;

      let rentsSummary = await getRequest(
        `/views/rent_costs_by_activity_slice?q=activity_slice_id:${activitySlice.id}`,
        true
      );
      if (!rentsSummary.success) {
        hasError = true;
        errorDataArray.push(rentsSummary);
      } else {
        // Get the first row of the summary
        let rentSummary = kRowsToItems(
          rentsSummary.data?.rows,
          rentsSummary.data.keys
        )[0];

        let rentPlanResponse = await getRequest(
          `/engagement_rent_payment_plan?q=engagement_id:${activitySlice.engagementId}`,
          true
        );
        if (!rentPlanResponse.success) {
          hasError = true;
          errorDataArray.push(rentPlanResponse);
        } else {
          let rentPlan = rentPlanResponse.data.sort(
            (a, b) => new Date(a.date) > new Date(b.date)
          );

          activitySlice.rentSummary = rentSummary;
          activitySlice.rentPaymentPlan = rentPlan;
          activitySlice.rentTotalQq = activitySliceData.rentTotalQq;
        }
      }
    }
  }

  // Ton based costs
  if (key === "assignedTonCosts") {
    let assignedTonCostsResponse = await getRequest(
      `/views/assigned_ton_costs_by_activity_slice?q=activity_slice_id:${activitySlice.id}`,
      true
    );
    if (!assignedTonCostsResponse.success) {
      hasError = true;
      errorDataArray.push(assignedTonCostsResponse);
    } else {
      let assignedTonCosts = kRowsToItems(
        assignedTonCostsResponse.data?.rows,
        assignedTonCostsResponse.data.keys
      );

      activitySlice.assignedTonCosts = assignedTonCosts;
    }
  }
  // Variable Sharecropping
  if (key === "variableShareCrop") {
    let variableShareCropResponse = await getRequest(
      "/variable_share_crop_schedule?q=" +
        "activity_slice_id:" +
        activitySlice.id
    );
    if (!variableShareCropResponse.success) {
      hasError = true;
      errorDataArray.push(variableShareCropResponse);
    } else {
      activitySlice.variableShareCropSchedule = variableShareCropResponse.data;
    }
  }

  // Harvest Events
  if (key === "harvestEvents") {
    let harvestEventsResponse = await getRequest(
      "/harvest_event?q=" + "activity_slice_id:" + activitySlice.id
    );
    if (!harvestEventsResponse.success) {
      hasError = true;
      errorDataArray.push(harvestEventsResponse);
    } else {
      activitySlice.harvestEvents = harvestEventsResponse.data;
    }
  }

  if (hasError) {
    errorDataArray.unshift({
      success: false,
      data: {
        type: "alert",
        text: "Uno o más errores ocurrieron mientras se actualizaba el cache",
      },
    });
  }
  return {
    success: hasError,
    errorDataArray: errorDataArray,
    calledByUpdateSingleActivitySliceCache: true,
    ...activitySlice,
  };
}

async function updateSingleDraftActivitySliceCache(draftActivitySlice, key) {
  // Function to return an updated single draft activity slice object
  // key is used to determine what part of the object needs updating
  let validKeys = [
    "assignedSCostItems",
    "assignedTonCosts",
    "summary",
    "rents",
    "variableShareCrop",
    "harvestEvents",
  ];

  let hasError = false;
  let errorDataArray = [];
  if (!validKeys.includes(key)) {
    return {
      success: false,
      type: "error",
      text: `${key} not not in keys. Cant refresh cache.`,
    };
  }
  // Assigned direct slice costs
  if (key === "assignedSCostItems") {
    let draftAssignedSliceCostDataResponse = await getRequest(
      `/views/draft_assigned_direct_slice_costs_by_activity_slice?q=draft_activity_slice_id:${draftActivitySlice.id}`,
      true
    );
    if (!draftAssignedSliceCostDataResponse.success) {
      hasError = true;
      errorDataArray.push(draftAssignedSliceCostDataResponse);
    } else {
      let assignedSliceCostData = draftAssignedSliceCostDataResponse.data;
      let assignedSCostItems = kRowsToItems(
        assignedSliceCostData?.rows,
        assignedSliceCostData.keys
      );
      draftActivitySlice.assignedSCostItems = assignedSCostItems;
      let summaryResponse = await getRequest(
        `/views/draft_activity_slice_summary?q=draft_activity_slice_id:${draftActivitySlice.id}`,
        true
      );
      if (!summaryResponse.success) {
        hasError = true;
        errorDataArray.push(summaryResponse);
      } else {
        let summary = kRowsToItems(
          summaryResponse.data?.rows,
          summaryResponse.data.keys
        )[0];

        draftActivitySlice.summary = summary;
      }
    }
  }
  // Summary statistics
  if (key === "summary") {
    // Update the summary and activity_slice fields
    let summaryResponse = await getRequest(
      `/views/draft_activity_slice_summary?q=draft_activity_slice_id:${draftActivitySlice.id}`,
      true
    );
    if (!summaryResponse.success) {
      hasError = true;
      errorDataArray.push(summaryResponse);
    } else {
      let actSliResponse = await getRequest(
        `/draft_activity_slice/${draftActivitySlice.id}`
      );
      if (!actSliResponse.success) {
        hasError = true;
        errorDataArray.push(actSliResponse);
      } else {
        let updatableKeys = [
          "assignedHectares",
          "forwardYield",
          "minHarvestCost",
          "maxHarvestCost",
          "harvestPercentCost",
          "freightCostTn",
          "fixedSharecroppingPercent",
        ];

        for (let i = 0; i < updatableKeys.length; i++) {
          const key = updatableKeys[i];
          draftActivitySlice[key] = actSliResponse.data[key];
        }

        let summary = kRowsToItems(
          summaryResponse.data?.rows,
          summaryResponse.data.keys
        )[0];

        draftActivitySlice.summary = summary;
      }
    }
  }
  // Rents summary
  if (key === "rents") {
    let as = await getRequest(`/draft_activity_slice/${draftActivitySlice.id}`);
    if (!as.success) {
      hasError = true;
      errorDataArray.push(as);
    } else {
      let activitySliceData = as.data;

      let rentsSummary = await getRequest(
        `/views/draft_rent_costs_by_activity_slice?q=draft_activity_slice_id:${draftActivitySlice.id}`,
        true
      );
      if (!rentsSummary.success) {
        hasError = true;
        errorDataArray.push(rentsSummary);
      } else {
        // Get the first row of the summary
        let rentSummary = kRowsToItems(
          rentsSummary.data?.rows,
          rentsSummary.data.keys
        )[0];

        let rentPlanResponse = await getRequest(
          `/draft_engagement_rent_payment_plan?q=draft_engagement_id:${draftActivitySlice.draftEngagementId}`,
          true
        );
        if (!rentPlanResponse.success) {
          hasError = true;
          errorDataArray.push(rentPlanResponse);
        } else {
          let rentPlan = rentPlanResponse.data.sort(
            (a, b) => new Date(a.date) > new Date(b.date)
          );

          draftActivitySlice.rentSummary = rentSummary;
          draftActivitySlice.rentPaymentPlan = rentPlan;
          draftActivitySlice.rentTotalQq = activitySliceData.rentTotalQq;
        }
      }
    }
  }

  // Ton based costs
  if (key === "assignedTonCosts") {
    let assignedTonCostsResponse = await getRequest(
      `/views/draft_assigned_ton_costs_by_activity_slice?q=draft_activity_slice_id:${draftActivitySlice.id}`,
      true
    );
    if (!assignedTonCostsResponse.success) {
      hasError = true;
      errorDataArray.push(assignedTonCostsResponse);
    } else {
      let assignedTonCosts = kRowsToItems(
        assignedTonCostsResponse.data?.rows,
        assignedTonCostsResponse.data.keys
      );

      draftActivitySlice.assignedTonCosts = assignedTonCosts;
    }
  }
  // Variable Sharecropping
  if (key === "variableShareCrop") {
    let variableShareCropResponse = await getRequest(
      "/draft_variable_share_crop_schedule?q=" +
        "draft_activity_slice_id:" +
        draftActivitySlice.id
    );
    if (!variableShareCropResponse.success) {
      hasError = true;
      errorDataArray.push(variableShareCropResponse);
    } else {
      draftActivitySlice.variableShareCropSchedule =
        variableShareCropResponse.data;
    }
  }
  // Harvest Events
  if (key === "harvestEvents") {
    let harvestEventsResponse = await getRequest(
      "/draft_harvest_event?q=" +
        "draft_activity_slice_id:" +
        draftActivitySlice.id
    );
    if (!harvestEventsResponse.success) {
      hasError = true;
      errorDataArray.push(harvestEventsResponse);
    } else {
      draftActivitySlice.harvestEvents = harvestEventsResponse.data;
    }
  }
  if (hasError) {
    errorDataArray.unshift({
      success: false,
      data: {
        type: "alert",
        text: "Uno o más errores ocurrieron mientras se actualizaba el cache",
      },
    });
  }
  return {
    success: hasError,
    errorDataArray: errorDataArray,
    calledByUpdateSingleDraftActivitySliceCache: true,
    ...draftActivitySlice,
  };
}

function optionize(v) {
  {
    return {
      id: v.listedDirectSliceCostItemId,
      value:
        v.directSliceCostItemName +
        ` - ( $${v.price} ${v.unitMeasure} ${
          v.regionName ? v.regionName : ""
        } ${v.activityName ? v.activityName : ""}) (${
          v.costItemFamilyName
        }) - ${v.campaignId}`,
      ...v,
    };
  }
}

async function getSliceProductsList() {
  // We have to bring all of the products
  let response = await getRequest(
    "/views/slice_products_list?limit=10000",
    true
  );
  if (!response.success) {
    return response;
  } else {
    let data = response.data;
    let productsItems = kRowsToItems(data?.rows, data.keys);
    let result = {
      keys: data.keys.map((i) => {
        return { text: i, value: i };
      }),
      items: productsItems,
      options: productsItems.map(optionize),
    };
    return { success: true, data: result };
  }
}

async function getSliceProductsListByItemId(itemId, campaignId, skip, limit) {
  let endpoint = `/views/slice_products_list?offset=${skip}&limit=${limit}&q=direct_slice_cost_item_id:${itemId}`;
  if (campaignId) {
    endpoint = endpoint + `,campaign_id:${campaignId}`;
  }

  let response = await getRequest(endpoint, true);
  if (!response.success) {
    return response;
  } else {
    let data = response.data;
    let productsItems = kRowsToItems(data?.rows, data.keys);
    let result = {
      success: true,
      data: productsItems,
    };
    return result;
  }
}

export {
  getASByID,
  getDraftASByID,
  kRowsToItems,
  URL,
  parseStringToNumber,
  regexDecimalsSeparator,
  regexThousandsSeparator,
  findMaxInvestmentValue,
  makeSingleActivitySliceInfoGrid,
  makeSingleDraftActivitySliceInfoGrid,
  getSliceProductsList,
  getSliceProductsListByItemId,
  validateRequestObject,
  updateSingleActivitySliceCache,
  updateSingleDraftActivitySliceCache,
  makeCampaignInfoGrid,
  makeCampaignSimulationInfoGrid,
};
